import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Flex from 'ui/flex';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import Spacer from 'ui/spacer';
import { Checkbox } from 'antd';
import V2Table from 'ui/v2/table/table';
import { useGetAfuData } from 'business/resources/osr/services/osrAfu.service';
import {
  AFU_RENEWED_COLUMNS,
  AFU_TO_RENEW_COLUMNS,
  PROOF_OF_USED_RENEWED_COLUMNS,
  PROOF_OF_USED_TO_RENEW_COLUMNS,
} from './trademarkOsr.constant';
import SearchBar from 'ui/v2/tableActions/search';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';

interface ITrademarkAffidavits {
  filters: OsrFilterState;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
}

const AffidavitList = (props: ITrademarkAffidavits) => {
  const {
    afuToRenew,
    afuRenewed,
    proofOfUseRenewed,
    proofOfUseToRenew,
    generateExcel,
  } = useGetAfuData({
    countryCode: props.filters.countryCode,
    companyRef: props.filters.companyRef,
    dueDate: props.filters.dueDate,
  });

  return (
    <OsrTabs
      tabsItems={[
        {
          key: 'affidavitsTable',
          label: `Affidavits à traiter (${afuToRenew.data.total})`,
          children: (
            <V2Table
              columns={afuToRenew.columns.tableColumns}
              dataSource={afuToRenew.data.data}
              onChange={afuToRenew.columns.handlePagination}
              loading={afuToRenew.data.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: afuToRenew.columns.paginationState.current,
                pageSize: afuToRenew.columns.paginationState.pageSize,
                total: afuToRenew.data.total,
              }}
              actions={
                <Spacer align="center" size="small">
                  <SearchBar
                    value={afuToRenew.data.searchAfuToRenew}
                    onDebouncedChange={(e) =>
                      afuToRenew.data.setSearchAfuToRenew(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel()}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={AFU_TO_RENEW_COLUMNS}
                    visibleColumns={afuToRenew.columns.visibleColumns}
                    onChange={afuToRenew.columns.toggleColumnVisibility}
                  />
                  <>
                    <Checkbox
                      defaultChecked={afuToRenew.data.firstAffidavitEvent}
                      onChange={() =>
                        afuToRenew.data.setFirstAffidavitEvent(
                          !afuToRenew.data.firstAffidavitEvent,
                        )
                      }
                    >
                      1er délai affidavit
                    </Checkbox>
                    <Checkbox
                      defaultChecked={afuToRenew.data.lastDeadlineAffidavit}
                      onChange={() =>
                        afuToRenew.data.setLastDeadlineAffidavit(
                          !afuToRenew.data.lastDeadlineAffidavit,
                        )
                      }
                    >
                      Dernier délai affidavit
                    </Checkbox>

                    <Checkbox
                      defaultChecked={afuToRenew.data.excludingArgentina}
                      onChange={() =>
                        afuToRenew.data.setExcludingArgentina(
                          !afuToRenew.data.excludingArgentina,
                        )
                      }
                    >
                      Hors Argentine
                    </Checkbox>
                  </>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'affidavitDoneTable',
          label: `Affidavits traités (${afuRenewed.data.total})`,
          children: (
            <V2Table
              columns={afuRenewed.columns.tableColumns}
              dataSource={afuRenewed.data.data}
              onChange={afuRenewed.columns.handlePagination}
              loading={afuRenewed.data.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: afuRenewed.columns.paginationState.current,
                pageSize: afuRenewed.columns.paginationState.pageSize,
                total: afuRenewed.data.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={afuRenewed.data.searchAfuRenewed}
                    onDebouncedChange={(e) =>
                      afuRenewed.data.setSearchAfuRenewed(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel()}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={AFU_RENEWED_COLUMNS}
                    visibleColumns={afuRenewed.columns.visibleColumns}
                    onChange={afuRenewed.columns.toggleColumnVisibility}
                  />
                  <Checkbox
                    defaultChecked={afuRenewed.data.affidavitAr}
                    onChange={() =>
                      afuRenewed.data.setAffidavitAr(
                        !afuRenewed.data.affidavitAr,
                      )
                    }
                  >
                    Sans AR
                  </Checkbox>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'trademark_proof_of_use_to_renew_table',
          label: `P. d’usage liée à Ren. à traiter (${proofOfUseToRenew.data.total})`,
          children: (
            <V2Table
              columns={proofOfUseToRenew.columns.tableColumns}
              dataSource={proofOfUseToRenew.data.data}
              onChange={proofOfUseToRenew.columns.handlePagination}
              loading={proofOfUseToRenew.data.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: proofOfUseToRenew.columns.paginationState.current,
                pageSize: proofOfUseToRenew.columns.paginationState.pageSize,
                total: proofOfUseToRenew.data.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={proofOfUseToRenew.data.searchProofOfUsedToRenew}
                    onDebouncedChange={(e) =>
                      proofOfUseToRenew.data.setSearchProofOfUsedToRenew(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel()}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={PROOF_OF_USED_TO_RENEW_COLUMNS}
                    visibleColumns={proofOfUseToRenew.columns.visibleColumns}
                    onChange={proofOfUseToRenew.columns.toggleColumnVisibility}
                  />
                  <Checkbox
                    defaultChecked={proofOfUseToRenew.data.excludingArgentina}
                    onChange={() =>
                      proofOfUseToRenew.data.setExcludingArgentina(
                        !proofOfUseToRenew.data.excludingArgentina,
                      )
                    }
                  >
                    Hors Argentine
                  </Checkbox>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'trademark_proof_of_use_renewed_table',
          label: `P. d’usage liées à Ren. traitées (${proofOfUseRenewed.data.total})`,
          children: (
            <V2Table
              columns={proofOfUseRenewed.columns.tableColumns}
              dataSource={proofOfUseRenewed.data.data}
              onChange={proofOfUseRenewed.columns.handlePagination}
              loading={proofOfUseRenewed.data.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: proofOfUseToRenew.columns.paginationState.current,
                pageSize: proofOfUseToRenew.columns.paginationState.pageSize,
                total: proofOfUseRenewed.data.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={proofOfUseRenewed.data.searchProofOfUsedRenewed}
                    onDebouncedChange={(e) =>
                      proofOfUseRenewed.data.setSearchProofOfUsedRenewed(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel()}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={PROOF_OF_USED_RENEWED_COLUMNS}
                    visibleColumns={proofOfUseToRenew.columns.visibleColumns}
                    onChange={proofOfUseToRenew.columns.toggleColumnVisibility}
                  />
                </Spacer>
              }
            />
          ),
        },
      ]}
      actions={
        <Spacer size="small" direction="vertical">
          <Flex column style={{ width: '33%' }}>
            <Spacer direction="vertical" size="small">
              <RangePicker
                allowClear
                defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
                onChange={(e) => {
                  props.onChangeRangeDate(
                    e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                    e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                  );
                }}
              />
            </Spacer>
          </Flex>
        </Spacer>
      }
    />
  );
};

export default AffidavitList;
